import React, {useEffect} from "react";
import { connect } from 'react-redux';
import { fetchSMSData, fetchUser, fetchCitiesList } from '../../actions';

import "./main.css";
import "./shortcuts.css";
import "./forms.css";
import LoginScreen from "../login-screen/login-screen";
import SMSModule from "../modules/SMS/sms-module";
import OrganizationsModule from "../modules/Organizations/organizations-module";
import RequestsModule from "../modules/Requests/requests-module";
import ServicesModule from "../modules/Services/services-module";
import EditOrganizations from "../modules/EditOrganizations/edit-organizations";
import CreateOrganization from "../modules/CreateOrganization/create-organization";
import DebtorsModule from "../modules/Debtors/debtors-module";
import CommonModule from "../modules/Common/common-module";
import EmptyModule from "../modules/EmptyOrganizations/empty-module";
import UrlsModule from "../modules/Urls/urls-module";
import CitiesModule from "../modules/Cities/cities-module";
import EmailsModule from "../modules/Emails/emails-module";

const App = props =>  {

    const { token, userId, fetchCitiesList } = props;

    const isStats = userId === "606596695b83e75dbfaacd74";

    useEffect(() => {
        if (token) {
            fetchCitiesList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    return (
        <div className="wrapper">
            <div className="container">
                <div className="mt-30">
                    <h1>Мой Город</h1>
                    <h4>Администрирование</h4>
                    <div className="mt-30">
                        {token === "" &&
                            <LoginScreen />
                        }
                        {token !== "" &&
                            <div>
                                {isStats &&
                                    <div className="d-flex-wrap">
                                        <CommonModule />
                                        <EmptyModule />
                                        <SMSModule />
                                        <DebtorsModule />
                                        <OrganizationsModule />
                                        <RequestsModule />
                                        <ServicesModule />
                                        <UrlsModule />
                                        <CitiesModule />
                                        <EmailsModule />
                                    </div>
                                }
                                {!isStats &&
                                <div className="">
                                    <SMSModule />
                                    <CommonModule />
                                    <CreateOrganization />
                                    <EditOrganizations />
                                </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({  data: { token, userId }}) => {
  return { token, userId }
};

const mapDispatchToProps = (dispatch) => {
  return {
      fetchCitiesList: fetchCitiesList(dispatch),
      fetchSMSData: fetchSMSData(dispatch),
      fetchUser: fetchUser(dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
