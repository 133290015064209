import React, {useState} from "react";
import { connect } from 'react-redux';
import { fetchCommonData } from "../../../actions";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const CommonModule = props =>  {

    const { common, fetchCommonData, citiesList } = props;
    const [city, setCity] = useState('all');

    const orgsWithLogins = common && common.all ? common.all.map(item => {return {...item, login: item.userId?.login || null}}) : [];

    return (
        <div className="tile">
            <h5>Организации: статистика</h5>
            <div className="mt-10 d-flex-ac">
                <div className="w-100">Город:</div>
                <select className='w-150' value={city} onChange={(e) => setCity(e.target.value)} >
                    {citiesList &&
                    [{city: 'all', label: 'Все'}, ...citiesList].map((item, index) => <option key={index} value={item.city}>{item.label}</option>)
                    }
                </select>
            </div>
            <div className="mt-10">
                <button onClick={() => fetchCommonData(city)}>Загрузить</button>

            </div>
            <div className="mt-30">
                {common &&
                <div>
                    <p className="mb-10"><strong>{`Всего: ${common.active + common.inactive + common.stage}`}</strong></p>
                    <p className="mb-10">{`Активных: ${common.active || ""}`}</p>
                    <p className="mb-10">{`Неактивных: ${common.inactive || ""}`}</p>
                    <p className="mb-10">{`Тестовых: ${common.stage || ""}`}</p>
                    <div className='mt-20'>
                        <p className="mt-20">Скачать заполняемость организаций</p>
                        <ExcelFile>
                            <ExcelSheet data={orgsWithLogins} name="Organizations">
                                <ExcelColumn label="Организация" value="mainLabel"/>
                                <ExcelColumn label="Краткое название" value="shortLabel"/>
                                <ExcelColumn label="Категория" value="mainCategory"/>
                                <ExcelColumn label="Логин" value="login"/>
                                <ExcelColumn label="Тип" value="category"/>
                                <ExcelColumn label="Информационных блоков" value={(col) => col.infosection.length}/>
                                <ExcelColumn label="Новостей" value={(col) => col.news.length}/>
                                <ExcelColumn label="Сервисов / категорий" value={(col) => col.services.length}/>
                            </ExcelSheet>
                        </ExcelFile>

                    </div>

                </div>
                }

            </div>
        </div>
    );
}

const mapStateToProps = ({  data: { common, citiesList }}) => {
    return { common, citiesList }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCommonData: fetchCommonData(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonModule);
