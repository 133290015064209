import React, {useState} from "react";
import { connect } from 'react-redux';
import { fetchEmptyData } from "../../../actions";

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const EmptyModule = props =>  {

    const { empty, fetchEmptyData, citiesList } = props;
    const [city, setCity] = useState('all');

    return (
        <div className="tile">
            <h5>Организации: Полностью пустые</h5>
            <div className="mt-10 d-flex-ac">
                <div className="w-100">Город:</div>
                <select className='w-150' value={city} onChange={(e) => setCity(e.target.value)} >
                    {citiesList &&
                        [{city: 'all', label: 'Все'}, ...citiesList].map((item, index) => <option key={index} value={item.city}>{item.label}</option>)
                    }
                </select>
            </div>
            <div className="mt-10">
                <button onClick={() => fetchEmptyData(city)}>Загрузить</button>

            </div>
            <div className="mt-30">
                {empty &&
                <div>
                    <p>{"Всего организаций: " + empty.length}</p>
                    <div className='mt-20'>
                        <p className="mb-20">Скачать незаполненные организации</p>
                        <ExcelFile>
                            <ExcelSheet data={empty} name="Organizations">
                                <ExcelColumn label="Организация" value="mainLabel"/>
                                <ExcelColumn label="Краткое название" value="shortLabel"/>
                            </ExcelSheet>
                        </ExcelFile>

                    </div>

                </div>
                }

            </div>
        </div>
    );
}

const mapStateToProps = ({  data: { empty, citiesList }}) => {
    return { empty, citiesList }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchEmptyData: fetchEmptyData(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmptyModule);
