import React, { useState } from "react";
import { connect } from 'react-redux';
import { fetchRequestsData } from "../../../actions";

const RequestsModule = props =>  {

    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');

    const {requests} = props;

    return (
        <div className="tile">
            <h5>Открытия приложения</h5>
            <div className="mt-20">
                От: <input type="date" value={from} onChange={(e) => setFrom(e.target.value)} />
            </div>
            <div className="mt-10">
                До: <input type="date" value={to} onChange={(e) => setTo(e.target.value)} />
            </div>
            <div className="mt-10">
                <button onClick={() => props.fetchRequestsData(from, to)}>Загрузить</button>
            </div>
            <div className="mt-30">
                {requests && requests.length > 0 &&
                <div>
                    <p>{"Всего запросов: " + requests.length}</p>
                    <p className="mb-20">Скачать итоговые данные</p>
                </div>
                }

            </div>
        </div>
    );
}

const mapStateToProps = ({  data: { requests }}) => {
    return { requests }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchRequestsData: fetchRequestsData(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestsModule);
