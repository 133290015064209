import React, {useEffect, useState} from "react";
import { connect } from 'react-redux';
import {
    fetchServicesData,
    fetchAllOrganizationsData,
} from "../../../actions";
import { CATEGORIES } from "../../../constants/constants";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ServicesModule = props =>  {

    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [city, setCity] = useState('all');

    const {services, organizations, fetchAllOrganizationsData, citiesList} = props;

    useEffect(() => {
        fetchAllOrganizationsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCategory = (orgLabel) => {
        const category = organizations.find(item => item.mainLabel === orgLabel);
        if (category) {
            const categoryLabel =  CATEGORIES.find(item => item.value === category.mainCategory);
            if (categoryLabel) {
                return categoryLabel.label || ''
            } else return ''
        } else return ''
    }

    const getTransformedServices = () => {
        const uniqueServices = [];
        const uniqueOrganizations = [];
        const uniqueCategories = [];

        services.forEach(service => {
            const {serviceHeadingValue, organizationMainLabel} = service;
            const categoryLabel = getCategory(organizationMainLabel);

            const serviceItem = uniqueServices.find(item => item.serviceHeadingValue === serviceHeadingValue && item.organizationMainLabel === organizationMainLabel);
            if (serviceItem) {
                serviceItem.serviceCounter ++;
            } else {
                uniqueServices.push({
                    serviceHeadingValue,
                    organizationMainLabel,
                    categoryLabel,
                    serviceCounter: 1,
                })
            }

            const organizationItem = uniqueOrganizations.find(item => item.organizationMainLabel === organizationMainLabel);
            if (organizationItem) {
                organizationItem.organizationCounter ++;
            } else {
                uniqueOrganizations.push({
                    organizationMainLabel,
                    categoryLabel,
                    organizationCounter: 1,
                })
            }

            const categoryItem = uniqueCategories.find(item => item.categoryLabel === categoryLabel);
            if (categoryItem) {
                categoryItem.categoryCounter ++;
            } else {
                uniqueCategories.push({
                    categoryLabel,
                    categoryCounter: 1,
                })
            }

        })

        uniqueCategories.sort((a, b) => b.categoryCounter - a.categoryCounter);
        uniqueOrganizations.sort((a, b) => b.organizationCounter - a.organizationCounter);
        uniqueServices.sort((a, b) => b.serviceCounter - a.serviceCounter);

        const summary = [];
        uniqueCategories.forEach((categoryItem, categoryIndex) => {
            const orgs = uniqueOrganizations.filter(org => org.categoryLabel === categoryItem.categoryLabel);
            orgs.forEach((orgItem, orgIndex) => {
                const services = uniqueServices.filter(srv => srv.organizationMainLabel === orgItem.organizationMainLabel);
                services.forEach((serviceItem, serviceIndex) => {
                    const categoryLabel = serviceIndex === 0 && orgIndex === 0 ? categoryItem.categoryLabel : ''
                    const categoryCounter = serviceIndex === 0 && orgIndex === 0 ? categoryItem.categoryCounter : ''
                    const orgLabel = serviceIndex === 0 ? orgItem.organizationMainLabel : ''
                    const orgCounter = serviceIndex === 0 ? orgItem.organizationCounter : ''
                    const serviceLabel = serviceItem.serviceHeadingValue
                    const serviceCounter = serviceItem.serviceCounter
                    summary.push({categoryLabel, categoryCounter, orgLabel, orgCounter, serviceLabel, serviceCounter})
                })
            })
        })

       return summary
    }

    let servicesTransformed = services;
    if (services.length > 0 && organizations.length > 0) {
        servicesTransformed = getTransformedServices()
    }

    return (
        <div className="tile">
            <h5>Записи на сервисы</h5>
            <div className="mt-10 d-flex-ac">
                <div className="w-100">Город:</div>
                <select className='w-150' value={city} onChange={(e) => setCity(e.target.value)} >
                    {citiesList &&
                    [{city: 'all', label: 'Все'}, ...citiesList].map((item, index) => <option key={index} value={item.city}>{item.label}</option>)
                    }
                </select>
            </div>
            <div className="mt-20">
                От: <input type="date" value={from} onChange={(e) => setFrom(e.target.value)} />
            </div>
            <div className="mt-10">
                До: <input type="date" value={to} onChange={(e) => setTo(e.target.value)} />
            </div>
            <div className="mt-10">
                <button onClick={() => props.fetchServicesData(from, to, city)}>Загрузить</button>
            </div>
            <div className="mt-30">
                {services && services.length > 0 &&
                <div>
                    <p>{"Всего запросов: " + services.length}</p>
                    <p className="mt-20">Скачать итоговые данные</p>
                    <ExcelFile>
                        <ExcelSheet data={services} name="Employees">
                            <ExcelColumn label="Дата и время" value="date"/>
                            <ExcelColumn label="ID Организации" value="organizationId"/>
                            <ExcelColumn label="Название организации" value="organizationMainLabel" />
                            <ExcelColumn label="Название сервиса" value="serviceHeadingValue" />
                            <ExcelColumn label="Дата записи" value="serviceDate" />
                            <ExcelColumn label="Время записи" value="serviceTime" />
                            <ExcelColumn label="Имя пользователя" value="userName" />
                            <ExcelColumn label="Телефон" value="userPhone" />
                            <ExcelColumn label="Тип записи" value="serviceType" />
                        </ExcelSheet>
                    </ExcelFile>

                    <p className="mt-20">Скачать сгрупированные данные</p>
                    <ExcelFile>
                        <ExcelSheet data={servicesTransformed} name="Services">
                            <ExcelColumn label="Категория" value="categoryLabel"/>
                            <ExcelColumn label="к-во в категории" value="categoryCounter"/>
                            <ExcelColumn label="Организация" value="orgLabel" />
                            <ExcelColumn label="к-во в Организации" value="orgCounter" />
                            <ExcelColumn label="Сервис" value="serviceLabel" />
                            <ExcelColumn label="к-во заявок" value="serviceCounter" />
                        </ExcelSheet>
                    </ExcelFile>

                </div>
                }

            </div>
        </div>
    );
}

const mapStateToProps = ({  data: { services, organizations, citiesList }}) => {
    return { services, organizations, citiesList }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchServicesData: fetchServicesData(dispatch),
        fetchAllOrganizationsData: fetchAllOrganizationsData(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesModule);
