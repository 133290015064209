import React, {useEffect, useState} from "react";
import { connect } from 'react-redux';
import {
    fetchUrlsData,
    fetchAllOrganizationsData,
} from "../../../actions";
import { CATEGORIES } from "../../../constants/constants";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const UrlsModule = props =>  {

    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [city, setCity] = useState('all');

    const {urls, organizations, fetchAllOrganizationsData, fetchUrlsData, citiesList} = props;

    useEffect(() => {
        fetchAllOrganizationsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCategory = (orgLabel) => {
        const category = organizations.find(item => item.mainLabel === orgLabel);
        if (category) {
            const categoryLabel =  CATEGORIES.find(item => item.value === category.mainCategory);
            if (categoryLabel) {
                return categoryLabel.label || ''
            } else return ''
        } else return ''
    }

    const getTransformedUrls = () => {
        const uniqueUrls = [];
        const uniqueCategories = [];

        urls.forEach(org => {

            const { urlRequested, organizationMainLabel } = org

            const categoryLabel = getCategory(organizationMainLabel);

            const organizationItem = uniqueUrls.find(item => item.urlRequested === urlRequested);
            if (organizationItem) {
                organizationItem.organizationCounter ++;
            } else {
                uniqueUrls.push({
                    urlRequested,
                    categoryLabel,
                    organizationCounter: 1,
                })
            }

            const categoryItem = uniqueCategories.find(item => item.categoryLabel === categoryLabel);
            if (categoryItem) {
                categoryItem.categoryCounter ++;
            } else {
                uniqueCategories.push({
                    categoryLabel,
                    categoryCounter: 1,
                })
            }

        })

        uniqueCategories.sort((a, b) => b.categoryCounter - a.categoryCounter);
        uniqueUrls.sort((a, b) => b.organizationCounter - a.organizationCounter);

        const summary = [];
        uniqueCategories.forEach((categoryItem, categoryIndex) => {
            const orgs = uniqueUrls.filter(org => org.categoryLabel === categoryItem.categoryLabel);
            orgs.forEach((orgItem, orgIndex) => {

                const categoryLabel = orgIndex === 0 ? categoryItem.categoryLabel : ''
                const categoryCounter = orgIndex === 0 ? categoryItem.categoryCounter : ''
                const orgLabel = orgItem.urlRequested
                const orgCounter = orgItem.organizationCounter
                summary.push({categoryLabel, categoryCounter, orgLabel, orgCounter})
            })
        })

        return summary
    }

    const urlsTransformed = getTransformedUrls();

    return (
        <div className="tile">
            <h5>Открытия ссылок</h5>
            <div className="mt-10 d-flex-ac">
                <div className="w-100">Город:</div>
                <select className='w-150' value={city} onChange={(e) => setCity(e.target.value)} >
                    {citiesList &&
                    [{city: 'all', label: 'Все'}, ...citiesList].map((item, index) => <option key={index} value={item.city}>{item.label}</option>)
                    }
                </select>
            </div>
            <div className="mt-20">
                От: <input type="date" value={from} onChange={(e) => setFrom(e.target.value)} />
            </div>
            <div className="mt-10">
                До: <input type="date" value={to} onChange={(e) => setTo(e.target.value)} />
            </div>
            <div className="mt-10">
                <button onClick={() => fetchUrlsData(from, to, city)}>Загрузить</button>
            </div>
            <div className="mt-30">
                {urls && urls.length > 0 &&
                <div>
                    <p>{"Всего открытий ссылок: " + urls.length}</p>
                    <p className="mt-20">Скачать итоговые данные</p>
                    <ExcelFile>
                        <ExcelSheet data={urls} name="Employees">
                            <ExcelColumn label="Дата и время" value="date"/>
                            <ExcelColumn label="ID Организации" value="organizationId"/>
                            <ExcelColumn label="Название организации" value="organizationMainLabel" />
                            <ExcelColumn label="Ссылка" value="urlRequested" />
                        </ExcelSheet>
                    </ExcelFile>

                    <p className="mt-20">Скачать сгрупированные данные</p>
                    <ExcelFile>
                        <ExcelSheet data={urlsTransformed} name="Services">
                            <ExcelColumn label="Категория" value="categoryLabel"/>
                            <ExcelColumn label="к-во в категории" value="categoryCounter"/>
                            <ExcelColumn label="Ссылка" value="orgLabel" />
                            <ExcelColumn label="к-во в Ссылках" value="orgCounter" />
                        </ExcelSheet>
                    </ExcelFile>

                </div>
                }

            </div>
        </div>
    );
}

const mapStateToProps = ({  data: { urls, organizations, citiesList }}) => {
    return { urls, organizations, citiesList }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUrlsData: fetchUrlsData(dispatch),
        fetchAllOrganizationsData: fetchAllOrganizationsData(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UrlsModule);
