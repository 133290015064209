import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import {fetchAllOrganizationsData, fetchEditOrganization, fetchDeleteOrganization} from "../../../actions";
import EditOrganization from "./edit-organization";
import {CATEGORIES} from "../../../constants/constants";

const EditOrganizations = props =>  {

    useEffect(() => {

        props.fetchAllOrganizationsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [search, setSearch] = useState('');

    const {organizations, citiesList} = props;

    const editOrganizationHandler = (item) => {
        props.fetchEditOrganization(item)
    }

    const deleteOrganizationHandler = (id) => {
        props.fetchDeleteOrganization(id)
    }

    const filteredOrganizations = search.length < 3 ? [] : organizations.filter(item => (item.mainLabel?.toLowerCase().indexOf(search.toLowerCase()) > -1 || item.shortLabel?.toLowerCase().indexOf(search.toLowerCase()) > -1))

    return (
        <div className="tile wp-100">
            <h5>Управление организациями</h5>
            <div className="mt-20 d-flex-ac">
                <input placeholder="Поиск по названию" type="text" className="wp-100" value={search} onChange={(e) => setSearch(e.target.value)} />
            </div>
            {
                <div className="mt-20">
                    <p><strong>Найденные организации:</strong></p>
                    {filteredOrganizations.length > 0 &&
                        filteredOrganizations.map((item, index) =>
                            <EditOrganization
                                key={index}
                                item={item}
                                categories={CATEGORIES}
                                cities={citiesList}
                                callback={(obj) => editOrganizationHandler(obj)}
                                callbackDelete={(id) => deleteOrganizationHandler(id)}
                            />)
                    }
                </div>
            }


        </div>
    );
}

const mapStateToProps = ({  data: { organizations, citiesList }}) => {
    return { organizations, citiesList }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAllOrganizationsData: fetchAllOrganizationsData(dispatch),
        fetchDeleteOrganization: fetchDeleteOrganization(dispatch),
        fetchEditOrganization: fetchEditOrganization(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOrganizations);
