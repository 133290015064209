import React, {useState} from "react";
import { connect } from 'react-redux';
import {
    fetchEmailsData,
} from "../../../actions";

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const EmailsModule = props =>  {

    const [city, setCity] = useState('all');

    const {emails, fetchEmailsData, citiesList} = props;

    return (
        <div className="tile">
            <h5>Список Emailов</h5>
            <div className="mt-10 d-flex-ac">
                <div className="w-100">Город:</div>
                <select className='w-150' value={city} onChange={(e) => setCity(e.target.value)} >
                    {citiesList &&
                    [{city: 'all', label: 'Все'}, ...citiesList].map((item, index) => <option key={index} value={item.city}>{item.label}</option>)
                    }
                </select>
            </div>
            <div className="mt-10">
                <button onClick={() => fetchEmailsData(city)}>Загрузить</button>
            </div>
            <div className="mt-30">
                {emails && emails.length > 0 &&
                <div>
                    <p>{"Всего открытий ссылок: " + emails.length}</p>
                    <p className="mt-20">Скачать итоговые данные</p>
                    <ExcelFile>
                        <ExcelSheet data={emails} name="Emails">
                            <ExcelColumn label="Email" value="email"/>
                        </ExcelSheet>
                    </ExcelFile>

                </div>
                }

            </div>
        </div>
    );
}

const mapStateToProps = ({  data: { emails, citiesList }}) => {
    return { emails, citiesList }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchEmailsData: fetchEmailsData(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailsModule);
