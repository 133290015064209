import React, { useState } from "react";
import { connect } from 'react-redux';
import { fetchDebtorsData } from "../../../actions";
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DebtorsModule = props =>  {

    const [loading, setLoading] = useState(false);
    const [city, setCity] = useState('all');

    const { debtors, fetchDebtorsData, citiesList } = props;

    const startLoading = () => {
        setLoading(true)
        fetchDebtorsData(city)
    }

    return (
        <div className="tile">
            <h5>Организации - должники</h5>
            <div className="mt-10 d-flex-ac">
                <div className="w-100">Город:</div>
                <select className='w-150' value={city} onChange={(e) => setCity(e.target.value)} >
                    {citiesList &&
                    [{city: 'all', label: 'Все'}, ...citiesList].map((item, index) => <option key={index} value={item.city}>{item.label}</option>)
                    }
                </select>
            </div>
            <div className="mt-10">
                <button onClick={startLoading}>Загрузить</button>
                {loading &&
                    <p className="mt-10">Загрузка...</p>
                }
            </div>
            <div className="mt-30">
                {debtors && debtors.length > 0 &&
                <div>
                    <p className="mb-20">Скачать итоговые данные</p>

                    <ExcelFile>
                        <ExcelSheet data={debtors} name="Employees">
                            <ExcelColumn label="Организация" value="organization"/>
                            <ExcelColumn label="Сервис" value="service"/>
                        </ExcelSheet>
                    </ExcelFile>

                </div>
                }

            </div>
        </div>
    );
}

const mapStateToProps = ({  data: { debtors, citiesList }}) => {
    return { debtors, citiesList }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDebtorsData: fetchDebtorsData(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DebtorsModule);
