import React, { useState } from "react";
import { connect } from 'react-redux';
import {fetchCreateUser} from "../../../actions";
import { CATEGORIES } from "../../../constants/constants";

const CreateOrganization = props =>  {

    const [label, setLabel] = useState('');
    const [category, setCategory] = useState('rik');
    const [city, setCity] = useState('polotsk');

    const {lastuser, citiesList} = props;

    return (
        <div className="tile wp-100">
            <h5>Создание организации</h5>
            <div className="mt-20 d-flex-ac">
                <div className="w-100">Название:</div>
                <input type="text" className="wp-100" value={label} onChange={(e) => setLabel(e.target.value)} />
            </div>
            <div className="mt-10 d-flex-ac">
                <div className="w-100">Город:</div>
                <select className='w-150' value={city} onChange={(e) => setCity(e.target.value)} >
                    {citiesList &&
                        citiesList.map((item, index) => <option key={index} value={item.city}>{item.label}</option>)
                    }
                </select>
            </div>
            <div className="mt-10 d-flex-ac">
                <div className="w-100">Категория:</div>
                <select value={category} onChange={(e) => setCategory(e.target.value)} >
                    {CATEGORIES &&
                        CATEGORIES.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)
                    }
                </select>
            </div>
            <div className="mt-10">
                <button onClick={() => props.fetchCreateUser({mainLabel: label, mainCategory: category, mainImage: "empty", city: city})}>Создать</button>
            </div>
            <div className="mt-20">
                {lastuser && lastuser.userId &&
                <div>
                    <p ><strong>Имя пользователя:</strong></p>
                    <p className="mt-5">{lastuser.login}</p>
                    <p className="mt-10"><strong>Пароль:</strong></p>
                    <p className="mt-5">{lastuser.password}</p>
                    <p className="mt-10"><strong>ID Организации:</strong></p>
                    <p className="mt-5">{lastuser.organizationId}</p>

                </div>
                }

            </div>
        </div>
    );
}

const mapStateToProps = ({  data: { lastuser, citiesList }}) => {
    return { lastuser, citiesList }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCreateUser: fetchCreateUser(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrganization);
