import React, {useEffect, useState} from "react";
import { connect } from 'react-redux';
import {
    fetchCitiesData,
    fetchAllOrganizationsData,
} from "../../../actions";

const CitiesModule = props =>  {

    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [city, setCity] = useState('all');

    const {cities, fetchAllOrganizationsData, fetchCitiesData, citiesList} = props;

    useEffect(() => {
        fetchAllOrganizationsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="tile">
            <h5>Переключения города</h5>
            <div className="mt-10 d-flex-ac">
                <div className="w-100">Город:</div>
                <select className='w-150' value={city} onChange={(e) => setCity(e.target.value)} >
                    {citiesList &&
                    [{city: 'all', label: 'Все'}, ...citiesList].map((item, index) => <option key={index} value={item.city}>{item.label}</option>)
                    }
                </select>
            </div>
            <div className="mt-20">
                От: <input type="date" value={from} onChange={(e) => setFrom(e.target.value)} />
            </div>
            <div className="mt-10">
                До: <input type="date" value={to} onChange={(e) => setTo(e.target.value)} />
            </div>
            <div className="mt-10">
                <button onClick={() => fetchCitiesData(from, to, city)}>Загрузить</button>
            </div>
            <div className="mt-30">
                {cities && cities.length > 0 &&
                <div>
                    <p>{"Всего переключений на город: " + cities.length}</p>

                </div>
                }

            </div>
        </div>
    );
}

const mapStateToProps = ({  data: { cities, organizations, citiesList }}) => {
    return { cities, organizations, citiesList }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCitiesData: fetchCitiesData(dispatch),
        fetchAllOrganizationsData: fetchAllOrganizationsData(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CitiesModule);
