
import store from "../../store";

const SERVER_URL = process.env.REACT_APP_API_URL ?
    process.env.REACT_APP_API_URL : "";

export default class APIService {

  getAsyncFetch = (urladdon, requestOptions={}, serverToLoad= SERVER_URL) => {

    console.log("requesting data from: " + (serverToLoad + urladdon));

    return fetch(serverToLoad + urladdon, requestOptions)
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            return res.json()
          } else {
            return Promise.reject(res.statusText);
          }
        })
        .then(res => {
          if(res === undefined) {
            return Promise.reject("Произошла ошибка загрузки данных. Мы обязательно исправим ее в ближайшее время");
          }
          return Promise.resolve(res.result || "")
        })
        .catch(error => {
          return Promise.reject(error);
        })
  }

  getUserData = async (login, pass) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "login": login,
        "password": pass
      })
    };

    return this.getAsyncFetch("/auth/login", requestOptions);
  };

  getSMSData = async (from, to, city='all') => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      body: JSON.stringify({
        from: from,
        to: to
      })
    };

    return this.getAsyncFetch("/sms?city=" + city, requestOptions);
  };

  getOrganizationsData = async (from, to, city='all') => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      body: JSON.stringify({
        from: from,
        to: to,
        request: "GET /api/organizations/:id",
        userRole: [null, 'common-user']
      })
    };

    return this.getAsyncFetch("/statistics/filtered/?city=" + city, requestOptions);
  };

  getDebtorsData = async (city='all') => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },
    };

    return this.getAsyncFetch("/statistics/debtors/?city=" + city, requestOptions);
  };

  getCommonStatsData = async (city='all') => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },
    };

    return this.getAsyncFetch("/statistics/org/?city=" + city, requestOptions);
  };

  getEmptyStatsData = async (city='all') => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },
    };

    return this.getAsyncFetch("/emptyorganizations?city=" + city, requestOptions);
  };

  getRequestsData = async (from, to, city='all') => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      body: JSON.stringify({
        from: from,
        to: to,
        request: "GET /api/organizations/without/sections",
        userRole: [null, 'common-user']
      })
    };

    return this.getAsyncFetch("/statistics/filtered/?city=" + city, requestOptions);
  };

  getServicesData = async (from, to, city='all') => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      body: JSON.stringify({
        from: from,
        to: to,
        request: "PUT /api/services/item/:id",
        userRole: [null, 'common-user']
      })
    };

    return this.getAsyncFetch("/statistics/filtered/?city=" + city, requestOptions);
  };

  getURLData = async (from, to, city='all') => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      body: JSON.stringify({
        from: from,
        to: to,
        request: "GET /api/url",
        userRole: [null, 'common-user']
      })
    };

    return this.getAsyncFetch("/statistics/filtered/?city=" + city, requestOptions);
  };

  getEmailsData = async (city='all') => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

    };

    return this.getAsyncFetch("/statistics/emails?city=" + city, requestOptions);
  };

  getCityData = async (from, to, city='all') => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      body: JSON.stringify({
        from: from,
        to: to,
        request: "GET /api/city",
        userRole: [null, 'common-user']
      })
    };

    return this.getAsyncFetch("/statistics/filtered/?city=" + city, requestOptions);
  };

  getCities = async () => {

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };

    return this.getAsyncFetch("/city", requestOptions);
  };

  getAllOrganizations = async (city='all') => {

    const requestOptions = {
      method: 'GET',
    };

    return this.getAsyncFetch("/organizations/without/sections?city=" + city, requestOptions);
  };

  createOrganization = async (obj) => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      body: JSON.stringify(obj)
    };

    return this.getAsyncFetch("/organizations", requestOptions);
  };

  createUser = async (obj) => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      body: JSON.stringify(obj)
    };

    return this.getAsyncFetch("/auth/registration", requestOptions);
  };

  editOrganizationRoot = async (obj) => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },

      body: JSON.stringify({organizations: [obj]})
    };

    return this.getAsyncFetch("/organizations/root/data", requestOptions);
  };

  deleteOrganizationRoot = async (id) => {

    const data = store.getState().data;

    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${data.token}`
      },
    };

    return this.getAsyncFetch(`/organizations/${id}`, requestOptions);
  };

}
