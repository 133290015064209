import APIService from "../services/api-service";


const organizationsLoaded = (data) => {
    return {
        type: 'FETCH_ORGANIZATIONS_SUCCESS',
        payload: data
    };
};

const organizationCreated = (data) => {
    return {
        type: 'FETCH_ORGANIZATION_CREATED',
        payload: data
    };
};

const dataSMSLoaded = (data) => {
    return {
        type: 'FETCH_SMS_DATA_SUCCESS',
        payload: data
    };
};

const dataOrgsLoaded = (data) => {
    return {
        type: 'FETCH_ORGS_DATA_SUCCESS',
        payload: data
    };
};

const dataDebtorsLoaded = (data) => {
    return {
        type: 'FETCH_DEBTORS_DATA_SUCCESS',
        payload: data
    };
};

const dataCommonLoaded = (data) => {
    return {
        type: 'FETCH_COMMON_DATA_SUCCESS',
        payload: data
    };
};

const dataEmptyLoaded = (data) => {
    return {
        type: 'FETCH_EMPTY_DATA_SUCCESS',
        payload: data
    };
};

const dataRequestsLoaded = (data) => {
    return {
        type: 'FETCH_REQUESTS_DATA_SUCCESS',
        payload: data
    };
};

const dataServicesLoaded = (data) => {
    return {
        type: 'FETCH_SERVICES_DATA_SUCCESS',
        payload: data
    };
};

const dataUrlsLoaded = (data) => {
    return {
        type: 'FETCH_URLS_DATA_SUCCESS',
        payload: data
    };
};

const dataEmailsLoaded = (data) => {
    return {
        type: 'FETCH_EMAILS_DATA_SUCCESS',
        payload: data
    };
};

const dataCitiesLoaded = (data) => {
    return {
        type: 'FETCH_CITIES_DATA_SUCCESS',
        payload: data
    };
};

const dataCitiesListLoaded = (data) => {
    return {
        type: 'FETCH_CITIES_LIST_SUCCESS',
        payload: data
    };
};

const dataUserLoaded = (data) => {
    return {
        type: 'FETCH_USER_DATA_SUCCESS',
        payload: data
    };
};

export const fetchUser = (dispatch) => (login, pass) => {
    const userService = new APIService();

    userService.getUserData(login, pass)
        .then((data) => dispatch(dataUserLoaded(data)))
        .catch((err) => console.log(err));
};


export const fetchSMSData = (dispatch) => (from, to, city) => {
    const userService = new APIService();

    userService.getSMSData(from, to, city)
        .then((data) => dispatch(dataSMSLoaded(data)))
        .catch((err) => console.log(err));
};

export const fetchOrganizationsData = (dispatch) => (from, to, city) => {
    const userService = new APIService();

    userService.getOrganizationsData(from, to, city)
        .then((data) => dispatch(dataOrgsLoaded(data)))
        .catch((err) => console.log(err));
};

export const fetchDebtorsData = (dispatch) => (city) => {
    const userService = new APIService();

    userService.getDebtorsData(city)
        .then((data) => dispatch(dataDebtorsLoaded(data)))
        .catch((err) => console.log(err));
};

export const fetchCommonData = (dispatch) => (city) => {
    const userService = new APIService();

    userService.getCommonStatsData(city)
        .then((data) => dispatch(dataCommonLoaded(data)))
        .catch((err) => console.log(err));
};

export const fetchEmptyData = (dispatch) => (city) => {
    const userService = new APIService();

    userService.getEmptyStatsData(city)
        .then((data) => dispatch(dataEmptyLoaded(data)))
        .catch((err) => console.log(err));
};

export const fetchRequestsData = (dispatch) => (from, to, city) => {
    const userService = new APIService();

    userService.getRequestsData(from, to, city)
        .then((data) => dispatch(dataRequestsLoaded(data)))
        .catch((err) => console.log(err));
};

export const fetchServicesData = (dispatch) => (from, to, city) => {
    const userService = new APIService();

    userService.getServicesData(from, to, city)
        .then((data) => dispatch(dataServicesLoaded(data)))
        .catch((err) => console.log(err));
};

export const fetchUrlsData = (dispatch) => (from, to, city) => {
    const userService = new APIService();

    userService.getURLData(from, to, city)
        .then((data) => dispatch(dataUrlsLoaded(data)))
        .catch((err) => console.log(err));
};

export const fetchEmailsData = (dispatch) => (city) => {
    const userService = new APIService();

    userService.getEmailsData(city)
        .then((data) => dispatch(dataEmailsLoaded(data)))
        .catch((err) => console.log(err));
};

export const fetchCitiesData = (dispatch) => (from, to, city) => {
    const userService = new APIService();

    userService.getCityData(from, to, city)
        .then((data) => dispatch(dataCitiesLoaded(data)))
        .catch((err) => console.log(err));
};

export const fetchCitiesList = (dispatch) => () => {
    const userService = new APIService();

    userService.getCities()
        .then((data) => dispatch(dataCitiesListLoaded(data)))
        .catch((err) => console.log(err));
};

export const fetchAllOrganizationsData = (dispatch) => (city) => {
    const userService = new APIService();

    userService.getAllOrganizations(city)
        .then((data) => dispatch(organizationsLoaded(data)))
        .catch((err) => console.log(err));
};

export const fetchEditOrganization = (dispatch) => (obj) => {
    const userService = new APIService();

    userService.editOrganizationRoot(obj)
        .then((data) => {

            if (data.length > 0) {
                window.alert("Организация отредактирована")
            }

            fetchAllOrganizationsData(dispatch)()

        })
        .catch((err) => console.log(err));
};

export const fetchDeleteOrganization = (dispatch) => (id) => {
    const userService = new APIService();

    userService.deleteOrganizationRoot(id)
        .then((data) => {

            window.alert("Организация удалена")

            fetchAllOrganizationsData(dispatch)()

        })
        .catch((err) => console.log(err));
};

export const fetchCreateUser = (dispatch) => (orgObj) => {
    const userService = new APIService();

    dispatch(organizationCreated({}))

    userService.createOrganization(orgObj)
        .then((data) => {

            if (!data._id) {
                console.log(data)
                return;
            }

            const newUser = {
                role: "common-admin",
                organizationId: data._id,
                login: "myadmin" + new Date().getTime().toString(),
                password: Math.random().toString(36).substr(2, 8)
            }

            userService.createUser(newUser)
                .then((user) => {
                    if (user.userId) {
                        dispatch(organizationCreated({...newUser, userId: user.userId}))
                        window.alert("Организация создана успешно")
                    } else {
                        window.alert("Ошибка создания организации")
                    }

                    fetchAllOrganizationsData(dispatch)()

                })
                .catch((err) => console.log(err));

        })
        .catch((err) => console.log(err));
};

