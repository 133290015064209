import React, { useState } from "react";
import { connect } from 'react-redux';
import {fetchSMSData} from "../../../actions";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const SMSModule = props =>  {

    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');

    const {SMSdata} = props;

    return (
        <div className="tile">
            <h5>Статистика по СМС</h5>

            <div className="mt-20">
                От: <input type="date" value={from} onChange={(e) => setFrom(e.target.value)} />
            </div>
            <div className="mt-10">
                До: <input type="date" value={to} onChange={(e) => setTo(e.target.value)} />
            </div>
            <div className="mt-10">
                <button onClick={() => props.fetchSMSData(from, to)}>Загрузить</button>
            </div>
            <div className="mt-30">
                {SMSdata && SMSdata.length > 0 &&
                    <div>
                        <p>{"Всего запросов: " + SMSdata.length}</p>
                        <p className="mb-20">Скачать итоговые данные</p>
                        <ExcelFile>
                            <ExcelSheet data={SMSdata} name="Employees">
                                <ExcelColumn label="Время зпроса" value="date"/>
                                <ExcelColumn label="Номер телефона" value="phone"/>
                                <ExcelColumn label="ID сообщения" value="messageId"/>
                                <ExcelColumn label="Статус сообщения" value="status"/>
                                <ExcelColumn label="Время отправки" value="sent"/>
                                <ExcelColumn label="Время получения" value="delivered"/>
                                <ExcelColumn label="Тип сообщения" value={(col) => col.phone.substring(0, 3) === "375" ? "национальное" : "международное"} />
                                <ExcelColumn label="Текст сообщения" value={(col) => "XXXXXX - Ваш проверочный код"}/>
                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                }

            </div>
        </div>
    );
}

const mapStateToProps = ({  data: { SMSdata }}) => {
    return { SMSdata }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchSMSData: fetchSMSData(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SMSModule);
