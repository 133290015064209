import React, {useEffect, useState} from "react";
import { connect } from 'react-redux';
import {fetchOrganizationsData, fetchAllOrganizationsData} from "../../../actions";

import ReactExport from "react-export-excel";
import { CATEGORIES } from "../../../constants/constants";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const OrganizationsModule = props =>  {

    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [city, setCity] = useState('all');

    const {ORGSdata, fetchAllOrganizationsData, organizations, citiesList} = props;

    useEffect(() => {
        fetchAllOrganizationsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCategory = (orgLabel) => {
        const category = organizations.find(item => item.mainLabel === orgLabel);
        if (category) {
            const categoryLabel =  CATEGORIES.find(item => item.value === category.mainCategory);
            if (categoryLabel) {
                return categoryLabel.label || ''
            } else return ''
        } else return ''
    }

    const getTransformedOrganizations = () => {
        const uniqueOrganizations = [];
        const uniqueCategories = [];

        ORGSdata.forEach(org => {

            const { organizationMainLabel } = org

            const categoryLabel = getCategory(organizationMainLabel);

            const organizationItem = uniqueOrganizations.find(item => item.organizationMainLabel === organizationMainLabel);
            if (organizationItem) {
                organizationItem.organizationCounter ++;
            } else {
                uniqueOrganizations.push({
                    organizationMainLabel,
                    categoryLabel,
                    organizationCounter: 1,
                })
            }

            const categoryItem = uniqueCategories.find(item => item.categoryLabel === categoryLabel);
            if (categoryItem) {
                categoryItem.categoryCounter ++;
            } else {
                uniqueCategories.push({
                    categoryLabel,
                    categoryCounter: 1,
                })
            }

        })

        uniqueCategories.sort((a, b) => b.categoryCounter - a.categoryCounter);
        uniqueOrganizations.sort((a, b) => b.organizationCounter - a.organizationCounter);

        const summary = [];
        uniqueCategories.forEach((categoryItem, categoryIndex) => {
            const orgs = uniqueOrganizations.filter(org => org.categoryLabel === categoryItem.categoryLabel);
            orgs.forEach((orgItem, orgIndex) => {

                const categoryLabel = orgIndex === 0 ? categoryItem.categoryLabel : ''
                const categoryCounter = orgIndex === 0 ? categoryItem.categoryCounter : ''
                const orgLabel = orgItem.organizationMainLabel
                const orgCounter = orgItem.organizationCounter
                summary.push({categoryLabel, categoryCounter, orgLabel, orgCounter})
            })
        })

        return summary
    }

    let organizationsTransformed = ORGSdata;
    if (ORGSdata.length > 0 && ORGSdata.length > 0) {
        organizationsTransformed = getTransformedOrganizations()
    }

    return (
        <div className="tile">
            <h5>Запросы организаций</h5>
            <div className="mt-10 d-flex-ac">
                <div className="w-100">Город:</div>
                <select className='w-150' value={city} onChange={(e) => setCity(e.target.value)} >
                    {citiesList &&
                    [{city: 'all', label: 'Все'}, ...citiesList].map((item, index) => <option key={index} value={item.city}>{item.label}</option>)
                    }
                </select>
            </div>
            <div className="mt-20">
                От: <input type="date" value={from} onChange={(e) => setFrom(e.target.value)} />
            </div>
            <div className="mt-10">
                До: <input type="date" value={to} onChange={(e) => setTo(e.target.value)} />
            </div>
            <div className="mt-10">
                <button onClick={() => props.fetchOrganizationsData(from, to, city)}>Загрузить</button>
            </div>
            <div className="mt-20">
                {ORGSdata && ORGSdata.length > 0 &&
                <div>
                    <p>{"Всего запросов: " + ORGSdata.length}</p>
                    <p className="mb-20">Скачать итоговые данные</p>
                    <ExcelFile>
                        <ExcelSheet data={ORGSdata} name="Employees">
                            <ExcelColumn label="Дата и время" value="date"/>
                            <ExcelColumn label="ID Организации" value="organizationId"/>
                            <ExcelColumn label="Название организации" value="organizationMainLabel" />
                            <ExcelColumn label="Город" value="city" />
                        </ExcelSheet>
                    </ExcelFile>

                    <p className="mt-20">Скачать сгрупированные данные</p>
                    <ExcelFile>
                        <ExcelSheet data={organizationsTransformed} name="Services">
                            <ExcelColumn label="Категория" value="categoryLabel"/>
                            <ExcelColumn label="к-во в категории" value="categoryCounter"/>
                            <ExcelColumn label="Организация" value="orgLabel" />
                            <ExcelColumn label="к-во в Организации" value="orgCounter" />
                        </ExcelSheet>
                    </ExcelFile>
                </div>
                }

            </div>
        </div>
    );
}

const mapStateToProps = ({  data: { ORGSdata, organizations, citiesList }}) => {
    return { ORGSdata, organizations, citiesList }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchOrganizationsData: fetchOrganizationsData(dispatch),
        fetchAllOrganizationsData: fetchAllOrganizationsData(dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationsModule);
