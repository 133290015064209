const updateData = (state, action) => {

    if (state === undefined) {
        return {
            token: "",
            userId: "",
            SMSdata: [],
            ORGSdata: [],
            requests: [],
            services: [],
            urls: [],
            emails: [],
            cities: [],
            debtors: [],
            common: {},
            empty: [],

            citiesList: [],
            organizations: [],
            lastuser: {}
        };
    }

    switch (action.type) {

        case 'FETCH_SMS_DATA_SUCCESS':
            return {
                ...state.data,
                SMSdata: action.payload.list,
            };

        case 'FETCH_ORGS_DATA_SUCCESS':
            return {
                ...state.data,
                ORGSdata: action.payload,
            };

        case 'FETCH_DEBTORS_DATA_SUCCESS':
            return {
                ...state.data,
                debtors: action.payload,
            };

        case 'FETCH_COMMON_DATA_SUCCESS':
            return {
                ...state.data,
                common: action.payload,
            };

            case 'FETCH_EMPTY_DATA_SUCCESS':
            return {
                ...state.data,
                empty: action.payload,
            };

        case 'FETCH_REQUESTS_DATA_SUCCESS':
            return {
                ...state.data,
                requests: action.payload,
            };

        case 'FETCH_SERVICES_DATA_SUCCESS':
            return {
                ...state.data,
                services: action.payload,
            };

        case 'FETCH_URLS_DATA_SUCCESS':
            return {
                ...state.data,
                urls: action.payload,
            };

        case 'FETCH_EMAILS_DATA_SUCCESS':
            return {
                ...state.data,
                emails: action.payload,
            };

        case 'FETCH_CITIES_DATA_SUCCESS':
            return {
                ...state.data,
                cities: action.payload,
            };

        case 'FETCH_CITIES_LIST_SUCCESS':
            return {
                ...state.data,
                citiesList: action.payload.map(el => {
                    return {
                        city: el.alias, label: el.name
                    }
                }).sort((a, b) => a.label.localeCompare(b.label)),
            };

        case 'FETCH_USER_DATA_SUCCESS':
            return {
                ...state.data,
                token: action.payload.token,
                userId: action.payload.userId
            };

        case 'FETCH_ORGANIZATIONS_SUCCESS':
            return {
                ...state.data,
                organizations: action.payload,
            };

        case 'FETCH_ORGANIZATION_CREATED':
            return {
                ...state.data,
                lastuser: action.payload,
            };


        default:
            return state.data;
    }
};

export default updateData;
