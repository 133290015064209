export const CATEGORIES = [
    {
        label: "Администрация города",
        value: "rik",
    },
    {
        label: "Банки",
        value: "banks",
    },
    {
        label: "Страхование",
        value: "insurance",
    },
    {
        label: "Строительство и Недвижимость",
        value: "buildings",
    },
    {
        label: "Нотариальные конторы",
        value: "laws",
    },
    {
        label: "Бизнес",
        value: "business",
    },
    {
        label: "Налоги",
        value: "taxes",
    },
    {
        label: "Транспорт",
        value: "transport",
    },
    {
        label: "Коммунальные услуги",
        value: "utility",
    },
    {
        label: "Спортивные школы и клубы",
        value: "sport_club",
    },
    {
        label: "Больницы",
        value: "healthcare",
    },
    {
        label: "Поликлиники",
        value: "clinic",
    },
    {
        label: "Аптеки",
        value: "pharmacy",
    },
    {
        label: "Медицинские центры и учреждения",
        value: "medical_centers",
    },
    {
        label: "Общественные организации",
        value: "teens",
    },
    {
        label: "РУП «Белпочта»",
        value: "post",
    },
    {
        label: "РУП «Белтелеком»",
        value: "telecom",
    },

    {
        label: "Детские сады",
        value: "education-kids",
    },

    {
        label: "Школы, Гимназии",
        value: "education-schools",
    },

    {
        label: "ВУЗы и ССУЗы",
        value: "education-special",
    },

    {
        label: "Центры системы образования",
        value: "education-centers",
    },

    {
        label: "Социальная защита",
        value: "social-protection",
    },

    {
        label: "Промышленноcть",
        value: "factory",
    },

    {
        label: "Сельское хозяйство",
        value: "agriculture",
    },
    {
        label: "Автобизнес",
        value: "auto-business",
    },
    {
        label: "Специальные службы",
        value: "special",
    },
    {
        label: "Ритуальные услуги",
        value: "rituals",
    },
    {
        label: "Туризм",
        value: "tourism",
    },
    {
        label: "Торговля",
        value: "shops",
    },
    {
        label: "Кафе и рестораны",
        value: "restaurants",
    },
    {
        label: "Организация праздников",
        value: "parties",
    },
    {
        label: "Сфера услуг",
        value: "people_services",
    },
    {
        label: "Учреждения культуры",
        value: "culture",
    },
    {
        label: "Активный отдых",
        value: "activerest",
    },
    {
        label: "Развлечения",
        value: "entertainment",
    },
    {
        label: "Музеи",
        value: "museums",
    },
    {
        label: "Религия",
        value: "religion",
    },
    {
        label: "СМИ и реклама",
        value: "media",
    },
    {
        label: "Мир животных",
        value: "animals",
    },
    {
        label: "Гостиницы",
        value: "hotels",
    },
]

export const CITIES = [
    {city: 'polotsk', label: 'Полоцк'},
    {city: 'glubokoe', label: 'Глубокое'},
    {city: 'pinsk', label: 'Пинск'},
    {city: 'lepel', label: 'Лепель'},
    {city: 'orsha', label: 'Орша'},
    {city: 'baranovichi', label: 'Барановичи'},
    {city: 'narovlya', label: 'Наровля'},
    {city: 'braslav', label: 'Браслав'},
    {city: 'zhodino', label: 'Жодино'},
    {city: 'smorgon', label: 'Сморгонь'},
    {city: 'bobruisk', label: 'Бобруйск'},
    {city: 'borisov', label: 'Борисов'},
    {city: 'nesvizsh', label: 'Несвиж'},
]
