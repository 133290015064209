import React, {useEffect, useState} from "react";

const ACTIVITY_STATUS = [
    {label: "Активна", value: "active"},
    {label: "Не активна", value: "inactive"},
    {label: "Тестовая", value: "stage"},
]

const TYPES = [
    {type: 'regular', label: 'Обычная'},
    {type: 'special', label: 'Специальная'},
    {type: 'news', label: 'Новостная'},
    {type: 'power', label: 'Комбинированная'},
]

const EditOrganization = props =>  {

    const {item, categories, callback, callbackDelete, cities} = props;

    const [label, setLabel] = useState(item.mainLabel || '');
    const [short, setShort] = useState(item.shortLabel || '');
    const [category, setCategory] = useState(item.mainCategory);
    const [secondary, setSecondary] = useState(item.secondaryCategory && item.secondaryCategory.length ? item.secondaryCategory[0] : '');
    const [city, setCity] = useState(item.city);
    const [type, setType] = useState(item.type);
    const [activity, setActivity] = useState((item.category === "inactive" || item.category === "stage") ? item.category : "active");

    useEffect(() => {
        setLabel(item.mainLabel || '');
        setShort(item.shortLabel || '');
        setCategory(item.mainCategory);
        setSecondary(item.secondaryCategory && item.secondaryCategory.length ? item.secondaryCategory[0] : '');
        setCity(item.city);
        setType(item.type);
        setActivity((item.category === "inactive" || item.category === "stage") ? item.category : "active");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    const sendEditCallback = () => {

        const objToSend = {
            _id: item._id,
            mainLabel: label,
            mainCategory: category,
            city: city,
            type: type,
            mainImage: item.mainImage,
            category: activity,
            ...(short && {shortLabel: short}),
            ...(secondary && {secondaryCategory: [secondary]})
        }

        callback(objToSend)
    }

    const sendDeleteCallback = () => {
        callbackDelete(item._id)
    }


    return (
        <div className="wp-100 mt-20">

            <div className='wp-100'>
                <p className='fs-10'>Полное название:</p>
                <div className='mt-5'>
                    <input type="text" placeholder="Полное название организации" className="wp-95" value={label} onChange={(e) => setLabel(e.target.value)} />
                </div>
            </div>

            <div className='wp-100 mt-10'>
                <p className='fs-10'>Краткое название:</p>
                <div className='mt-5'>
                    <input type="text" placeholder="Краткое название организации" className="wp-95" value={short} onChange={(e) => setShort(e.target.value)} />
                </div>
            </div>

            <div className='wp-100 mt-10'>
                <div className='d-flex-wrap-ac'>
                    <div className='mr-10 mbweb-10'>
                        <p className='fs-10'>Основная категория</p>
                        <select className="w-250 mt-5" value={category} onChange={(e) => setCategory(e.target.value)} >
                            {categories &&
                                categories.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)
                            }
                        </select>
                    </div>
                    <div className='mr-10 mbweb-10'>
                        <p className='fs-10'>Дополнительная категория</p>
                        <select className="w-250 mt-5" value={secondary} onChange={(e) => setSecondary(e.target.value)} >
                            {categories &&
                                ['', ...categories].map((item, index) => <option key={index} value={item.value}>{item.label}</option>)
                            }
                        </select>
                    </div>
                    <div className="mr-10 mbweb-10">
                        <p className='fs-10'>Город</p>
                        <select className="w-85 mt-5" value={city} onChange={(e) => setCity(e.target.value)} >
                            {cities &&
                                cities.map((item, index) => <option key={index} value={item.city}>{item.label}</option>)
                            }
                        </select>
                    </div>
                    <div className="mr-10 mbweb-10">
                        <p className='fs-10 '>Тип</p>
                        <select className="w-85 mt-5" value={activity} onChange={(e) => setActivity(e.target.value)} >
                            {ACTIVITY_STATUS &&
                                ACTIVITY_STATUS.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)
                            }
                        </select>
                    </div>
                    <div className="mr-10 mbweb-10">
                        <p className='fs-10'>Статус</p>
                        <select className="w-85 mt-5" value={type} onChange={(e) => setType(e.target.value)} >
                            {TYPES &&
                                TYPES.map((item, index) => <option key={index} value={item.type}>{item.label}</option>)
                            }
                        </select>
                    </div>
                </div>

            </div>

            <div className="mbweb-10 mt-10">
                <button className="button-blue" onClick={() => sendEditCallback()}>Обновить</button>
                <button onClick={() => window.confirm("Вы точно хотите удалить эту организацию?") &&
                    sendDeleteCallback()
                }>Удалить</button>
            </div>

            <div className='separator wp-100 mt-10' />

        </div>
    );
}

export default EditOrganization;
